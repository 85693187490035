<template>
  <div class="col-12 mt-16 mb-3">
    <div class="overflow-auto">
      <div class="mt-16">
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            align="right"
            class="m-0"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination } from "bootstrap-vue";

export default {
  name: 'PaginateQuisioner',
  data() {
    return {
      rows: 50,
      currentPage: 3,
    };
  },
  components: {
    BPagination,
  },
};
</script>
