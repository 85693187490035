<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" md="8" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Daftar Registrasi Kuesioner</h2>
      <div class="d-flex">
        <p class="hp-p1-body mb-0 mx-3">
          Semua (5)
        </p>
        <router-link to="/dashboards/add-quisioner">
          <b-button variant="primary-2"><i class="ri-add-line"/> Tambah Pertanyaan</b-button>
        </router-link>
      </div>
    </b-col>
    <TableListQuisioner/>
    <PaginateQuisioner/>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BButton
} from "bootstrap-vue";
import PaginateQuisioner from "./components/PaginateQuisioner";
import TableListQuisioner from "./components/TableListQuisioner";

export default {
  name: "Quisioners",
  components: {
    BRow,
    BCol,
    BButton,
    PaginateQuisioner,
    TableListQuisioner
  }
}
</script>

<style scoped>

</style>