<template>
  <div class="col-12">
    <b-table style="overflow: auto; white-space: nowrap;" :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" responsive="sm">
      <template v-slot:cell(aksi)="row">
        <b-button-group v-for="(item, index) in navigate">
          <router-link :to="item.to" :key="index">
            <b-button size="sm" class="mx-1" :variant="item.variant">
              <b-icon :icon="item.icon" class="mx-2"></b-icon>
              {{ item.name }}
            </b-button>
          </router-link>
        </b-button-group>
      </template>
      <template v-slot:cell(daftarPertanyaan)="row">
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20.6724" r="20" fill="#FF0022"/>
          <g clip-path="url(#clip0_710_25828)">
            <path
                d="M20 27.6724C20.828 27.6724 21.5 28.3444 21.5 29.1724C21.5 30.0004 20.828 30.6724 20 30.6724C19.172 30.6724 18.5 30.0004 18.5 29.1724C18.5 28.3444 19.172 27.6724 20 27.6724ZM20 10.6724C23.314 10.6724 26 13.3584 26 16.6724C26 18.8374 25.247 19.9624 23.326 21.5954C21.399 23.2324 21 23.9694 21 25.6724H19C19 23.1984 19.787 21.9774 22.031 20.0714C23.548 18.7824 24 18.1064 24 16.6724C24 14.4624 22.21 12.6724 20 12.6724C17.79 12.6724 16 14.4624 16 16.6724V17.6724H14V16.6724C14 13.3584 16.686 10.6724 20 10.6724Z"
                fill="#FEF7FD"/>
          </g>
          <defs>
            <clipPath id="clip0_710_25828">
              <rect width="24" height="24" fill="white" transform="translate(8 8.67236)"/>
            </clipPath>
          </defs>
        </svg>

        <span class="ml-2">{{ row.item.daftarPertanyaan }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import {BTable, BButton, BIcon, BButtonGroup} from "bootstrap-vue";

export default {
  name: 'TableListQuisioner',
  data() {
    return {
      sortBy: 'age',
      sortDesc: false,
      fields: [
        {key: 'no', sortable: true},
        {key: 'daftarPertanyaan', sortable: true},
        {key: 'aksi', sortable: false}
      ],
      items: [
        {no: 1, daftarPertanyaan: 'Apakah Anda memiliki riwayat penyakit jantung, hipertensi, atau stroke?'},
        {
          no: 2,
          daftarPertanyaan: 'Apakah Anda ingin mendonasikan darah utuh atau komponen darah tertentu (seperti plasma atau trombosit)?'
        },
        {no: 3, daftarPertanyaan: 'Apakah berat badan anda diatas 50kg?'},
      ],
      navigate: [
        {
          name: 'Hapus',
          icon: 'trash',
          variant: 'danger-2',
          to: ""
        },
        {
          name: 'Edit',
          icon: 'box-arrow-up-right',
          variant: 'info-1',
          to: "/dashboards/edit-quisioner"
        },
        {
          name: 'Detail',
          icon: 'menu-up',
          variant: 'success-1',
          to: "/dashboards/quisioner"
        }
      ]
    };
  },
  components: {
    BTable,
    BButton,
    BIcon,
    BButtonGroup
  },
};
</script>
